<template>
    <div ref="viewBoxContainer" class="d-flex align-items-center justify-content-center overflow-hidden">
        <div ref="viewBoxContent" :style="{width:contentWidth+'px',height:contentHeight+'px'}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            contentWidth: { type: Number, default: 100 },
            contentHeight: { type: Number, default: 100 },
        },
        methods: {
            resize() {
                let width = this.$refs.viewBoxContainer.clientWidth;
                let height = this.$refs.viewBoxContainer.clientHeight;
                let scaleX = width / this.contentWidth;
                let scaleY = height / this.contentHeight;
                if (scaleX < scaleY) {
                    this.$refs.viewBoxContent.style.transform = `scale(${scaleX})`;
                }
                else {
                    this.$refs.viewBoxContent.style.transform = `scale(${scaleY})`;
                }
            },
        },
        mounted() {
            this.resize();
        },
        created() {
            window.onresize = this.resize;
        },
    };
</script>