<template>
    <div class="vw-100 vh-100 screen-bg" style="color: white;">
        <div class="text-center" style="height: 120px;">
            <dv-border-box-11 title="水发智慧农业一体化平台" :title-width="320" style="margin-bottom: -20px;">
                <div class="text-warning" style="padding-top: 68px;">
                    {{summary}}
                </div>
            </dv-border-box-11>
        </div>
        <div class="row no-gutters" style="height: calc(100% - 120px);">
            <div class="col-3 h-100">
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link to="/ia" class="text-white">用户认证管理平台</router-link>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="d-flex align-items-center" style="height: calc(100% - 82px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.registeredUsers}}</div>
                                    <div class="text-info text-sm">注册用户</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.authenticatedEnterpriseUsers}}
                                    </div>
                                    <div class="text-info text-sm">实名个人</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.authenticatedIndividualUsers}}
                                    </div>
                                    <div class="text-info text-sm">实名企业</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-info text-sm">实名用户占比</div>
                            <dv-percent-pond
                                :config="{value:screenSystemStats.authenticatedUserRate.toFixed(2)*100,colors:['#01c4f9','#c135ff']}"
                                class="mt-1" style="height: 32px;" />
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link to="/fm" class="text-white">种植户管理平台</router-link>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="d-flex align-items-center" style="height: calc(100% - 27px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.individualFarmers}}</div>
                                    <div class="text-info text-sm">个人种植户</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.enterpriseFarmers}}</div>
                                    <div class="text-info text-sm">企业种植户</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 24px;">{{screenSystemStats.warrantors}}</div>
                                    <div class="text-info text-sm">担保人</div>
                                </div>
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link to="/lt" class="text-white">网上土地流转系统</router-link>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="d-flex align-items-center" style="height: calc(50% - 13px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col-auto px-3">
                                    <div class="text-info text-sm">流入</div>
                                    <div class="text-info text-sm">土地</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div :title="screenSystemStats.flowInLandsTotalAmount">
                                        {{(screenSystemStats.flowInLandsTotalAmount/10000).toFixed(2)}} 万元</div>
                                    <div class="text-info text-sm">合同总金额(元)</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div>{{(screenSystemStats.flowInLandsContractTotalArea/10000).toFixed(2)}} 万亩</div>
                                    <div class="text-info text-sm">合同总面积</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div>{{screenSystemStats.flowInLandsContractPrice.toFixed(2)}}</div>
                                    <div class="text-info text-sm">合同均价(元/亩)</div>
                                </div>
                            </div>
                        </div>
                        <div class="border-top"></div>
                        <div class="d-flex align-items-center" style="height: calc(50% - 13px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col-auto px-3">
                                    <div class="text-info text-sm">流出</div>
                                    <div class="text-info text-sm">土地</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div :title="screenSystemStats.flowOutLandsTotalAmount">
                                        {{(screenSystemStats.flowOutLandsTotalAmount/10000).toFixed(2)}} 万元</div>
                                    <div class="text-info text-sm">合同总金额</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div>{{(screenSystemStats.flowOutLandsContractTotalArea/10000).toFixed(2)}} 万亩</div>
                                    <div class="text-info text-sm">合同总面积</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div>{{screenSystemStats.flowOutLandsContractPrice.toFixed(2)}}</div>
                                    <div class="text-info text-sm">合同均价(元/亩)</div>
                                </div>
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link to="/lm" class="text-white">土地管理平台</router-link>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="d-flex align-items-center" style="height: calc(100% - 82px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.lands}}</div>
                                    <div class="text-info text-sm">土地(块)</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 20px;">
                                        {{(screenSystemStats.landsTotalArea/10000).toFixed(2)}} 万亩
                                    </div>
                                    <div class="text-info text-sm">总面积(亩)</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.landsWarning}}</div>
                                    <div class="text-info text-sm">30天到期预警(块)</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-info text-sm">测绘比例</div>
                            <dv-percent-pond :config="{value:screenSystemStats.landsMappingRate.toFixed(2)*100}"
                                class="mt-1" style="height: 32px;" />
                        </div>
                    </dv-border-box-13>
                </div>
            </div>
            <div class="col h-100">
                <div class="p-2" style="height: 75%;">
                    <dv-border-box-8 :dur="30" class="p-1">
                        <div class="h-100 position-relative">
                            <el-amap :center="map.center" :zoom="map.zoom" map-style="amap://styles/blue"
                                class="sc-amap">
                                <el-amap-marker v-for="(company, i) in companies" :key="'amapMarker_'+i"
                                    :position="[company.longitude,company.latitude]" :icon="markerImage"
                                    :offset="[-20,-20]" :events="company.markerEvents" />
                                <el-amap-text v-if="company&&company.fullName"
                                    :text="company.fullName+'<div class=\'text-sm o-50\'>'+company.phone+'<br/>'+company.address+'</div>'"
                                    :position="[company.longitude,company.latitude]" :offset="[0,-64]"
                                    :events="company.textEvents" />
                            </el-amap>
                            <div class="position-absolute text-center" style="top: 4px; left: 0; right: 0;">
                                <dv-decoration-7 style="height: 30px;">
                                    <el-popover v-model="changeCompanyPopoverVisible">
                                        <a slot="reference" href="javascript:void(0)" class="text-white mx-3">
                                            <span v-if="company">{{company.name}}</span>
                                            <span v-else>全部公司</span>
                                        </a>
                                        <div class="row no-gutters" style="width: 800px;">
                                            <div v-for="company in $store.state.companies" :key="company.id"
                                                :command="company.id" class="col-3" style="padding: 1px;">
                                                <div :class="{'active':company.id==companyId}" class="nav-button"
                                                    @click="changeCompany(company)">
                                                    {{company.name}}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </dv-decoration-7>
                            </div>
                            <div v-if="showVideo" class="position-absolute"
                                style="background-color: black; top: 0; bottom: 0; left: 0; right: 0; z-index: 100000;">
                                <video muted :src="startVideo" autoplay class="w-100 h-100" style="object-fit: cover;"
                                    @ended="videoEnded">
                                </video>
                            </div>
                        </div>
                    </dv-border-box-8>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link v-if="$store.state.user.userName=='admin'" to="/lad" class="text-white">
                                    田间农事自动化管控平台
                                </router-link>
                                <span v-else>田间农事自动化管控平台</span>
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="pt-2" style="height: calc(100% - 21px);">
                            <div class="row no-gutters text-center h-100">
                                <div class="col h-100">
                                    <img src="@/assets/demo/tqyb.png" class="d-block w-100 h-100">
                                </div>
                                <div class="col-auto pl-2"></div>
                                <div class="col h-100">
                                    <img src="@/assets/demo/sq.png" class="d-block w-100 h-100">
                                </div>
                                <div class="col-auto pl-2"></div>
                                <div class="col h-100">
                                    <img src="@/assets/demo/cq.png" class="d-block w-100 h-100">
                                </div>
                                <div class="col-auto pl-2"></div>
                                <div class="col h-100">
                                    <img src="@/assets/demo/sfj.png" class="d-block w-100 h-100">
                                </div>
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
            </div>
            <div class="col-3 h-100">
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link to="/pm" class="text-white">商城管理平台(供应链)</router-link>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="d-flex align-items-center" style="height: calc(100% - 27px);">
                            <div class="row no-gutters text-center w-100">
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.productOnSales}}</div>
                                    <div class="text-info text-sm">上架商品</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.productDealToday}}</div>
                                    <div class="text-info text-sm">今日成交(元)</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.individualSuppliers}}</div>
                                    <div class="text-info text-sm">个人供应商</div>
                                </div>
                                <div class="col-auto border-left"></div>
                                <div class="col">
                                    <div style="font-size: 20px;">{{screenSystemStats.enterpriseSuppliers}}</div>
                                    <div class="text-info text-sm">企业供应商</div>
                                </div>
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link v-if="$store.state.user.userName=='admin'" to="/cm" class="text-white">
                                    农作物成品安控平台
                                </router-link>
                                <span v-else>农作物成品安控平台</span>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="pt-2" style="height: calc(100% - 27px);">
                            <view-box :content-width="400" :content-height="135"
                                class="h-100 w-100 border border-primary">
                                <el-carousel height="135px">
                                    <el-carousel-item>
                                        <img src="@/assets/demo/1.png" class="w-100 h-100" style="object-fit: cover;">
                                    </el-carousel-item>
                                    <el-carousel-item>
                                        <img src="@/assets/demo/2.png" class="w-100 h-100" style="object-fit: cover;">
                                    </el-carousel-item>
                                    <el-carousel-item>
                                        <img src="@/assets/demo/3.png" class="w-100 h-100" style="object-fit: cover;">
                                    </el-carousel-item>
                                    <el-carousel-item>
                                        <img src="@/assets/demo/4.png" class="w-100 h-100" style="object-fit: cover;">
                                    </el-carousel-item>
                                </el-carousel>
                            </view-box>
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                <router-link v-if="$store.state.user.userName=='admin'" to="/pp" class="text-white">
                                    土地种植方案测报平台
                                </router-link>
                                <span v-else>土地种植方案测报平台</span>
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div style="height: calc(100% - 27px);">
                            <view-box :content-width="120" :content-height="120" class="h-100 w-100">
                                <dv-decoration-9 class="w-100 h-100">
                                    <div class="text-center">
                                        <div class="text-lg">{{screenSystemStats.experts}}</div>
                                        <div class="text-info text-sm">专家</div>
                                    </div>
                                </dv-decoration-9>
                            </view-box>
                        </div>
                    </dv-border-box-13>
                </div>
                <div class="p-2" style="height: 25%;">
                    <dv-border-box-13 class="p-3">
                        <div class="row no-gutters">
                            <div class="col">
                                生产监管系统
                                <dv-decoration-2 style="height: 6px;" />
                            </div>
                            <div class="col-auto">
                            </div>
                        </div>
                        <div class="pt-2" style="height: calc(100% - 27px);">
                            <div class="h-100">
                                <img src="@/assets/demo/zhc.png" class="d-block w-100 h-100" style="object-fit: cover;">
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
            </div>
        </div>

        <system-bar />


    </div>
</template>

<script>
    import SystemBar from './components/SystemBar.vue';
    import marker from '../../assets/marker.png';
    import start from '../../assets/start.mp4';

    export default {
        data() {
            return {
                showVideo: false,
                map: {
                    center: [104, 39],
                    zoom: 4,
                },
                markerImage: marker,
                startVideo: start,
                companies: [],
                company: null,
                summary: null,
                screenSystemStats: {
                    authenticatedUserRate: 0,
                    landsTotalArea: 0,
                    landsMappingRate: 0,
                    flowInLandsTotalAmount: 0,
                    flowInLandsContractTotalArea: 0,
                    flowInLandsContractPrice: 0,
                    flowOutLandsTotalAmount: 0,
                    flowOutLandsContractTotalArea: 0,
                    flowOutLandsContractPrice: 0,
                },
                changeCompanyPopoverVisible: false,
            };
        },
        components: { SystemBar },
        computed: {
            companyId() {
                if (!this.company) {
                    return null;
                }
                return this.company.id;
            },
        },
        methods: {
            videoEnded() {
                this.showVideo = false;
            },
            loadCompanies() {
                this.companies = this.$store.state.companies.filter(p => p.longitude).map(p => {
                    let company = JSON.parse(JSON.stringify(p));
                    company.markerEvents = {
                        click: (e) => {
                            this.map.center = [e.lnglat.lng, e.lnglat.lat];
                            if (this.company == company) {
                                this.company = null;
                            }
                            else {
                                this.company = company;
                            }
                            this.loadScreenSystemStats();
                        }
                    };
                    return company;
                });
            },
            changeCompany(company) {
                this.company = company;
                this.loadScreenSystemStats();
                this.changeCompanyPopoverVisible = false;
            },
            async loadScreenSummary() {
                let response = await this.$axios.get('/api/Stats/GetScreenSummary');
                this.summary = response.data;
            },
            async loadScreenSystemStats() {
                let companyId = null;
                if (this.company) {
                    companyId = this.company.id;
                }

                let response = await this.$axios.get('/api/Stats/GetScreenSystemStats', { params: { companyId: companyId } });
                this.screenSystemStats = response.data;
            },
        },
        async mounted() {
            if (this.$store.state.showStartVideo) {
                this.showVideo = true;
                this.$store.dispatch('setShowStartVideo', false);
            }
        },
        created() {
            if (this.$store.state.user.type != 1) {
                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
                return;
            }

            this.loadCompanies();
            this.loadScreenSummary();
            this.loadScreenSystemStats();
        },
    };
</script>

<style>
    .screen-bg {
        background-image: url(../../assets/bg.jpg);
    }
</style>