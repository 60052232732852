<template>
    <div :class="disabled?'is-disabled':''" class="el-textarea">
        <div :contenteditable="!readonly&&!disabled" :disabled="disabled" :placeholder="placeholder"
            class="el-textarea__inner overflow-auto html-editor" :style="editorStyle" @input="input">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: String, default: null },
            disabled: { type: Boolean, default: false },
            readonly: { type: Boolean, default: false },
            placeholder: { type: String },
            height: { type: String, default: 'auto' },
            minHeight: { type: String, default: '54px' },
            maxHeight: { type: String, default: 'auto' },
        },
        computed: {
            editorStyle() {
                return `height: ${this.height}; min-height: ${this.minHeight}; max-height: ${this.maxHeight};`;
            },
        },
        watch: {
            value(value) {
                if (this.$el.children[0].innerHTML != value) {
                    this.$el.children[0].innerHTML = value;
                }
            },
        },
        methods: {
            input() {
                this.$emit('input', this.$el.children[0].innerHTML);
            },
        },
        mounted() {
            this.$el.children[0].innerHTML = this.value;
        },
    };
</script>

<style>
    .html-editor:empty::before {
        content: attr(placeholder);
        color: #c0c4cc;
    }
</style>