<template>
    <div class="position-fixed panel px-3 py-2" style="top: 28px; right: 16px;">
        <!-- <router-link to="/admin" target="_blank">后台管理</router-link> -->
        <a href="javascript:void(0)" class="ml-4" @click="logout">注销</a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        methods: {
            logout() {
                if (!confirm('确定要注销吗？')) {
                    return;
                }

                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
            },
        },
    };
</script>