<template>
    <el-dialog :visible.sync="visible" width="100vw" top="0px" :close-on-click-modal="closeOnClickModal"
        :close-on-press-escape="closeOnPressEscape" append-to-body custom-class="content-dialog"
        class="hide-dialog-header" @closed="$emit('input',false)">
        <div class="overflow-auto" :style="bodyStyle">
            <div v-if="title" class="text-lg text-center font-weight-bold p-3 border-bottom border-light">
                {{title}}
            </div>
            <slot></slot>
        </div>

        <div v-if="showPrimaryButton||showSecondaryButton" class="p-3 bg-white border-top border-light">
            <div class="row no-gutters">
                <div v-if="showPrimaryButton" class="col">
                    <el-button :type="primaryButtonType" round class="w-100" @click="primaryButtonClick">
                        {{primaryButtonText}}
                    </el-button>
                </div>
                <div v-if="showPrimaryButton&&showSecondaryButton" class="col-auto" style="width: 16px;"></div>
                <div v-if="showSecondaryButton" class="col">
                    <el-button :type="secondaryButtonType" round class="w-100" @click="secondaryButtonClick">
                        {{secondaryButtonText}}
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            title: { type: String },
            fullscreen: { type: Boolean, default: false },
            showPrimaryButton: { type: Boolean, default: true },
            showSecondaryButton: { type: Boolean, default: true },
            primaryButtonText: { type: String, default: '确定' },
            secondaryButtonText: { type: String, default: '取消' },
            primaryButtonType: { type: String, default: 'primary' },
            secondaryButtonType: { type: String, default: '' },
            closeOnClickModal: { type: Boolean, default: true },
            closeOnPressEscape: { type: Boolean, default: true },
        },
        data() {
            return {
                visible: this.value,
            };
        },
        computed: {
            bodyStyle() {
                if (this.fullscreen) {
                    if (this.showPrimaryButton || this.showSecondaryButton) { return { height: 'calc(100vh - 73px)' }; }
                    else { return { height: '100vh' }; }
                }
                else {
                    return {};
                }
            },
        },
        watch: {
            value(value) {
                this.visible = value;
            },
        },
        methods: {
            primaryButtonClick() {
                this.$emit('primary-button-click');
            },
            secondaryButtonClick() {
                this.$emit('secondary-button-click');
            },
        },
    };
</script>

<style>
    .content-dialog.el-dialog {
        margin: 0 !important;
        border-radius: 0 !important;
    }

    .content-dialog .el-dialog__body {
        padding: 0 !important;
    }
</style>