<template>
    <div>
        <el-container class="vh-100">
            <el-aside width="250px" class="border-right" style="background-color: #16202f; user-select: none;">
                <el-container class="h-100">
                    <el-header height="160px">
                        <div class="h-100 d-flex flex-column justify-content-center align-items-center">
                            <el-avatar :size="64" icon="el-icon-user" class="bg-primary" style="font-size: 32px;" />
                            <el-dropdown trigger="click" placement="bottom" @command="userDropdown">
                                <el-button type="text" class="mt-3 p-0 text-white">
                                    <div>
                                        {{$store.state.user.name}}
                                        <i class="el-icon-arrow-down"></i>
                                    </div>
                                    <div class="mt-1 o-50">{{$store.state.user.userName}}</div>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item command="userProfile">用户信息</el-dropdown-item>
                                        <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
                                        <el-dropdown-item command="logout" divided class="text-danger">
                                            注销
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </el-header>
                    <el-main class="px-2 py-0 hidden-scrollbar">
                        <el-menu :default-openeds="expandedMenus" :default-active="defaultMenu"
                            background-color="#16202f" text-color="#cfcfcf" router class="border-0">
                            <template v-for="menuGroup in menus">
                                <template v-if="menuGroup.name">
                                    <el-submenu :key="menuGroup.name" :index="menuGroup.name">
                                        <template #title>
                                            <span class="o-50">{{menuGroup.name}}</span>
                                        </template>
                                        <el-menu-item v-for="menuItem in menuGroup.menuItems" :key="menuItem.path"
                                            :index="menuItem.path">
                                            <font-awesome-icon :icon="menuItem.icon" fixed-width
                                                style="font-size: 16px;" />
                                            <span class="ml-3">{{menuItem.name}}</span>
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                                <template v-else>
                                    <el-menu-item v-for="menuItem in menuGroup.menuItems" :key="menuItem.path"
                                        :index="menuItem.path">
                                        <font-awesome-icon :icon="menuItem.icon" fixed-width style="font-size: 16px;" />
                                        <span class="ml-3">{{menuItem.name}}</span>
                                    </el-menu-item>
                                </template>
                            </template>
                        </el-menu>
                    </el-main>
                </el-container>
            </el-aside>
            <el-main class="p-0">
                <el-container class="h-100">
                    <el-header class="bg-white shadow-sm row no-gutters" style="z-index: 1000;">
                        <div class="col d-flex align-items-center">
                            <img src="@/assets/logo.png" style="height: 40px;" />
                            <div class="ml-3 text-truncate">
                                <span style="font-size: 20px;">{{$store.state.applicationName}}</span>
                                <el-tag v-if="$store.state.dataDefinitions.isTestDatabase" type="danger" size="mini"
                                    effect="plain" class="ml-1">
                                    测试库
                                </el-tag>
                                <span class="text-info mx-3">|</span>
                                <router-link to="/" class="text-decoration-none">
                                    <font-awesome-icon icon="home" />
                                    {{systemName}}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <el-popover>
                                <a slot="reference" href="javascript:void(0)" class="text-md text-decoration-none">
                                    {{companyName}}
                                    <i class="el-icon-arrow-down"></i>
                                </a>
                                <div class="row no-gutters" style="width: 800px;">
                                    <div v-for="company in $store.state.companies" :key="company.id"
                                        :command="company.id" class="col-3" style="padding: 1px;">
                                        <div :class="{'active':company.id==companyId}" class="nav-button"
                                            @click="changeCompany(company.id)">
                                            {{company.name}}
                                        </div>
                                    </div>
                                </div>
                            </el-popover>
                        </div>
                    </el-header>
                    <el-main class="p-0">
                        <router-view />
                    </el-main>
                </el-container>
            </el-main>
        </el-container>

        <el-dialog :visible.sync="userProfileDialogVisible" title="用户信息" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="用户名">
                    <el-input v-model="updateUser.userName" readonly placeholder="用户名" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="updateUser.name" placeholder="姓名" />
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="updateUser.phone" placeholder="电话" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="updateUserProfile">确定</el-button>
                <el-button @click="userProfileDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="updatePasswordDialogVisible" title="修改密码" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="原密码">
                    <el-input type="password" v-model="password.originalPassword" placeholder="原密码" />
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input type="password" v-model="password.newPassword" placeholder="新密码" />
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input type="password" v-model="password.confirmationPassword" placeholder="确认密码" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="updatePassword">确定</el-button>
                <el-button @click="updatePasswordDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import menu from '../../menu';

    export default {
        data() {
            return {
                companyId: this.$store.state.companyId,
                companyName: this.$store.state.companyName,
                updateUser: {},
                password: {
                    originalPassword: null,
                    newPassword: null,
                    confirmationPassword: null,
                },
                defaultMenu: null,
                userProfileDialogVisible: false,
                updatePasswordDialogVisible: false,
            };
        },
        computed: {
            expandedMenus() {
                return this.menus.filter(p => p.expanded).map(p => p.name);
            },
            menus() {
                if (this.$route.path.indexOf('/lm') == 0) {
                    return menu.getLandManagementMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/fm') == 0) {
                    return menu.getFarmerManagementMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/lad') == 0) {
                    return menu.getLandAutoDeviceMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/lt') == 0) {
                    return menu.getLandTransferMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/pp') == 0) {
                    return menu.getPlantPredictMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/pm') == 0) {
                    return menu.getProductManagementMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/cm') == 0) {
                    return menu.getCropMonitoringMenus(this.$store.state.user);
                }
                else if (this.$route.path.indexOf('/ia') == 0) {
                    return menu.getIdentityAuthenticationMenus(this.$store.state.user);
                }
                else {
                    return [];
                }
            },
            systemName() {
                if (this.$route.path.indexOf('/lm') == 0) {
                    return '土地管理平台';
                }
                else if (this.$route.path.indexOf('/fm') == 0) {
                    return '种植户管理平台';
                }
                else if (this.$route.path.indexOf('/lad') == 0) {
                    return '田间农事自动化管控平台';
                }
                else if (this.$route.path.indexOf('/lt') == 0) {
                    return '网上土地流转系统';
                }
                else if (this.$route.path.indexOf('/pp') == 0) {
                    return '土地种植方案测报平台';
                }
                else if (this.$route.path.indexOf('/pm') == 0) {
                    return '商城管理平台(供应链)';
                }
                else if (this.$route.path.indexOf('/cm') == 0) {
                    return '农作物成品安控平台';
                }
                else if (this.$route.path.indexOf('/ia') == 0) {
                    return '用户认证管理平台';
                }
                else {
                    return '';
                }
            },
        },
        watch: {
            $route(value) {
                this.defaultMenu = value.path;
            },
        },
        methods: {
            checkPassword() {
                if (this.$store.state.user.isDefaultPassword) {
                    this.$notify({ type: 'warning', title: '请修改您的密码！', message: '您的密码不安全。', position: 'bottom-right', duration: 5 * 1000 });
                }
            },
            userDropdown(command) {
                if (command == 'userProfile') {
                    this.showUpdateUserProfile();
                }
                else if (command == 'updatePassword') {
                    this.showUpdatePassword();
                }
                else if (command == 'logout') {
                    this.logout();
                }
            },
            showUpdateUserProfile() {
                this.updateUser = JSON.parse(JSON.stringify(this.$store.state.user));
                this.userProfileDialogVisible = true;
            },
            showUpdatePassword() {
                this.password.originalPassword = null;
                this.password.newPassword = null;
                this.password.confirmationPassword = null;
                this.updatePasswordDialogVisible = true;
            },
            logout() {
                if (!confirm('确定要注销吗？')) {
                    return;
                }

                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
            },
            async updateUserProfile() {
                await this.$axios.post('/api/Account/UpdateUserProfile', {
                    name: this.updateUser.name,
                    phone: this.updateUser.phone,
                });
                this.userProfileDialogVisible = false;
                this.$message.success('用户信息修改成功。');
                setTimeout(() => { location.reload(); }, 200);
            },
            async updatePassword() {
                await this.$axios.post('/api/Account/UpdateUserPassword', {
                    originalPassword: this.password.originalPassword,
                    newPassword: this.password.newPassword,
                    confirmationPassword: this.password.confirmationPassword,
                });
                this.updatePasswordDialogVisible = false;
                this.$message.success('密码修改成功。');
            },
            changeCompany(value) {
                localStorage.companyId = value;
                this.$store.dispatch('setCompanyId', value);
                this.$message.info('正在刷新页面...');
                setTimeout(() => { location.reload(); }, 200);
            },
        },
        created() {
            document.title = this.$store.state.applicationName;
            this.defaultMenu = this.$route.path;

            if (this.$store.state.user.organizationId.indexOf('0001') != 0) {
                this.$router.replace('/');
            }

            this.checkPassword();
        },
    };
</script>

<style>
    .nav-button {
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
    }

    .nav-button.active {
        background-color: #ecf5ff;
        color: #409eff;
    }

    .nav-button:hover {
        background-color: #f2f6fc;
    }
</style>