<template>
    <el-amap :center="position" :zoom="zoom">
        <el-amap-marker :position="position" :label="label2" />
    </el-amap>
</template>

<script>
    export default {
        props: {
            position: { type: Array, default: () => [116.397455, 39.908741] },
            zoom: { type: Number, default: 15 },
            label: { type: String },
        },
        computed: {
            label2() {
                return { content: this.label, offset: [20, 0] };
            },
        },
    };
</script>

<style>
    .amap-logo,
    .amap-copyright {
        opacity: 0;
    }
</style>