import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import plugins from './plugins/register.js';
import components from './components/register.js';
import './styles/easy-styles.css';
import './styles/app.css';
import './styles/screen.css';

Vue.config.productionTip = false;

Vue.use(plugins);
Vue.use(components);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');