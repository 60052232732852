<template>
    <div>
        <el-dialog :visible.sync="visible" title="选择土地" :close-on-click-modal="false" append-to-body width="960px"
            top="0" @closed="$emit('input',false)">
            <div>
                <el-input :value="regionName" readonly placeholder="土地行政区划" style="width: 280px;">
                    <template #append>
                        <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                    </template>
                </el-input>
            </div>
            <div class="narrow-scrollbar border rounded overflow-auto mt-2" style="height: calc(100vh - 270px);">
                <el-table :data="lands.data">
                    <el-table-column prop="code" label="编号" sortable width="160" />
                    <el-table-column prop="name" label="名称" sortable />
                    <el-table-column prop="typeName" label="类型" sortable width="100" />
                    <el-table-column prop="area" label="面积" sortable width="100" />
                    <el-table-column label="操作" fixed="right" width="90">
                        <template #default="scope">
                            <el-button type="primary" size="small" @click="selectLand(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination layout="total,->,prev,pager,next" :total="lands.totalRecords"
                :current-page="landsPagination.currentPage" :page-size="landsPagination.pageSize" class="mt-3"
                @current-change="landsPaginationCurrentChange" />
            <template #footer>
                <el-button @click="$emit('input',false)">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="regionId=$event.id;regionName=$event.name;loadLands(true)" />
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                regionId: null,
                regionName: null,
                lands: {},
                landsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                selectRegionDialogVisible: false,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadLands();
                }
            },
        },
        methods: {
            async loadLands(resetPage = false) {
                if (resetPage) {
                    this.landsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Land/GetNotSignedLands', {
                    params: {
                        companyId: this.$store.state.companyId,
                        regionId: this.regionId,
                        pageIndex: this.landsPagination.currentPage - 1,
                        pageSize: this.landsPagination.pageSize,
                    }
                });
                this.lands = response.data;
            },
            landsPaginationCurrentChange(page) {
                this.landsPagination.currentPage = page;
                this.loadLands();
            },
            selectLand(land) {
                this.$emit('select', land);
                this.$emit('input', false);
            },
        },
    };
</script>