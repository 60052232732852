<template>
    <div>
        <el-dialog :visible.sync="visible" title="选择商品" :close-on-click-modal="false" append-to-body width="960px"
            top="0" @closed="$emit('input',false)">
            <div>
                <el-select v-model="productTypeId" placeholder="商品类型" style="width: 240px;"
                    @change="loadProducts(true)">
                    <el-option v-for="productType in productTypes" :key="productType.id" :value="productType.id"
                        :label="productType.name">
                        <span :style="{marginLeft: (productType.id.length-4)*4+'px'}">{{productType.name}}</span>
                    </el-option>
                </el-select>
                <search-box v-model="key" class="ml-2" style="width: 240px;" @search="loadProducts(true)" />
            </div>
            <div class="narrow-scrollbar border rounded overflow-auto mt-2" style="height: calc(100vh - 270px);">
                <el-table :data="products.data">
                    <el-table-column width="90">
                        <template #default="scope">
                            <el-image :src="scope.row.pictureUrl" :preview-src-list="[scope.row.pictureUrl]" fit="fill"
                                style="width: 64px; height: 64px;" />
                        </template>
                    </el-table-column>
                    <el-table-column label="名称">
                        <template #default="scope">
                            <div :title="scope.row.name" class="text-truncate">{{scope.row.name}}</div>
                            <div class="text-info">{{scope.row.productTypeName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="条形码/编号" width="150">
                        <template #default="scope">
                            <div>{{scope.row.barcode}}</div>
                            <div class="text-info">{{scope.row.number}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="计量单位/规格" width="150">
                        <template #default="scope">
                            <div>{{scope.row.unit}}</div>
                            <div class="text-info">{{scope.row.specification}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="90">
                        <template #default="scope">
                            <el-button type="primary" size="small" @click="selectProduct(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination layout="total,->,prev,pager,next" :total="products.totalRecords"
                :current-page="productsPagination.currentPage" :page-size="productsPagination.pageSize" class="mt-3"
                @current-change="productsPaginationCurrentChange" />
            <template #footer>
                <el-button @click="$emit('input',false)">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                productTypes: [],
                productTypeId: null,
                key: null,
                products: {},
                productsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadProducts();
                }
            },
        },
        methods: {
            async loadProductTypes() {
                let response = await this.$axios.get('/api/ProductType/GetProductTypes');
                this.productTypes = response.data;
            },
            async loadProducts(resetPage = false) {
                if (resetPage) {
                    this.productsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Product/GetProducts', {
                    params: {
                        //isOnSale:true,
                        productTypeId: this.productTypeId,
                        key: this.key,
                        pageIndex: this.productsPagination.currentPage - 1,
                        pageSize: this.productsPagination.pageSize,
                    }
                });
                this.products = response.data;
            },
            productsPaginationCurrentChange(page) {
                this.productsPagination.currentPage = page;
                this.loadProducts();
            },
            selectProduct(product) {
                this.$emit('select', product);
                this.$emit('input', false);
            },
        },
        created() {
            this.loadProductTypes();
        },
    };
</script>