<template>
    <el-dialog :visible.sync="visible" title="选择注册用户" :close-on-click-modal="false" append-to-body width="960px" top="0"
        @closed="$emit('input',false)">
        <div>
            <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
        </div>
        <div class="narrow-scrollbar border rounded overflow-auto mt-2" style="height: calc(100vh - 270px);">
            <el-table :data="users.data">
                <el-table-column prop="phone" label="注册登录手机号" sortable />
                <el-table-column prop="name" :label="nameText" sortable />
                <el-table-column prop="idCard" :label="idCardText" sortable />
                <el-table-column prop="createTime" label="注册时间" sortable :formatter="$moment.sdFormatter" width="120" />
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectUser(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
            :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
            @current-change="usersPaginationCurrentChange" />
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            isEnterprise: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        computed: {
            nameText() {
                return this.isEnterprise ? '企业名称' : '姓名';
            },
            idCardText() {
                return this.isEnterprise ? '统一社会信用代码' : '身份证号';
            },
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadUsers();
                }
            },
        },
        methods: {
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                if (this.isEnterprise) {
                    let response = await this.$axios.get('/api/RegisteredUser/GetEnterpriseUsers', {
                        params: {
                            companyId: this.$store.state.companyId,
                            authenticationStatus: 3,
                            key: this.key,
                            pageIndex: this.usersPagination.currentPage - 1,
                            pageSize: this.usersPagination.pageSize,
                        }
                    });
                    this.users = response.data;
                }
                else {
                    let response = await this.$axios.get('/api/RegisteredUser/GetIndividualUsers', {
                        params: {
                            companyId: this.$store.state.companyId,
                            authenticationStatus: 3,
                            key: this.key,
                            pageIndex: this.usersPagination.currentPage - 1,
                            pageSize: this.usersPagination.pageSize,
                        }
                    });
                    this.users = response.data;
                }
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            selectUser(user) {
                this.$emit('select', user);
                this.$emit('input', false);
            },
        },
    };
</script>