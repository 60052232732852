import ElementUI from 'element-ui';
import axios from 'axios';
import qs from 'qs';

let webAxios = axios.create();

// x-www-form-urlencoded
webAxios.interceptors.request.use(config => {
    if (config.method == 'post') {
        config.data = qs.stringify(config.data);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// handle error
webAxios.interceptors.request.use(config => {
    return config;
}, error => {
    handleError(error);
    return Promise.reject(error);
});
webAxios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status == 401) {
        localStorage.removeItem('token');
        location.reload();
    } else {
        handleError(error);
    }
    return Promise.reject(error);
});

function handleError(error) {
    if (error.response) {
        if (typeof(error.response.data) == 'object') {
            window.console.error('[Server error]: ' + error.response.data.message + '\n' + error.response.data.detail);
            let message = textToHtml(error.response.data.message);
            ElementUI.MessageBox.alert(message, '错误', { type: 'error', dangerouslyUseHTMLString: true });
        } else {
            if (error.response.data) {
                let message = textToHtml(error.response.data);
                ElementUI.MessageBox.alert(message, '错误', { type: 'error', dangerouslyUseHTMLString: true });
            } else {
                let message = textToHtml(error.message);
                ElementUI.MessageBox.alert(message, '错误', { type: 'error', dangerouslyUseHTMLString: true });
            }
        }
    } else {
        let message = textToHtml(error.message);
        ElementUI.MessageBox.alert(message, '错误', { type: 'error', dangerouslyUseHTMLString: true });
    }
}

function textToHtml(text) {
    text = text.replace(/ /g, '&nbsp;&nbsp;');
    text = text.replace(/</g, '&lt;');
    text = text.replace(/>/g, '&gt;');
    text = text.replace(/"/g, '&quot;');
    text = text.replace(/'/g, '&apos;');
    text = text.replace(/\r\n/g, '<br/>');
    text = text.replace(/\r/g, '<br/>');
    text = text.replace(/\n/g, '<br/>');
    return text;
}

function install(Vue) {
    Vue.prototype.$webAxios = webAxios;
}

export default { install };