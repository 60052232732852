export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/cm', component: () => import('../views/Admin/Home.vue') },
        ]
    },
    {
        name: '农产品安控',
        expanded: true,
        menuItems: [
            { permission: '', name: '摄像头监控', icon: 'video', path: '/cm/video', component: () => import('../views/Video/EZUIKitJs.vue') },
            { permission: '', name: '摄像头列表', icon: 'video', path: '/cm/video-list', component: () => import('../views/Video/Videolist.vue') },
        ]
    },
];