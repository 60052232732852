<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button @click="showRelevantPersonnelDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="relevantpersonnels" class="mt-2">
            <el-table-column prop="name" label="姓名" sortable />
            <el-table-column prop="idCard" label="身份证号" sortable width="170" />
            <el-table-column prop="relation" label="关系" sortable />
            <el-table-column prop="remark" label="备注" sortable />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showRelevantPersonnelDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteRelevantPersonnel(scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="relevantpersonnelDialogVisible" title="土地" :close-on-click-modal="false"
            append-to-body width="480px">
            <el-form>
                <el-form-item label="姓名">
                    <el-input v-model="relevantpersonnel.name" placeholder="姓名" />
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="relevantpersonnel.idCard" placeholder="身份证号" />
                </el-form-item>
                <el-form-item label="关系">
                    <el-input v-model="relevantpersonnel.relation" placeholder="关系" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="relevantpersonnel.remark" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveRelevantPersonnel">确定</el-button>
                <el-button @click="relevantpersonnelDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Array, default: () => [] },
        },
        data() {
            return {
                relevantpersonnels: this.value,
                relevantpersonnelOriginal: {},
                relevantpersonnel: {},
                relevantpersonnelDialogVisible: false,
            };
        },
        watch: {
            value(value) {
                this.relevantpersonnels = value;
            },
        },
        methods: {
            showRelevantPersonnelDialog(relevantpersonnel) {
                if (relevantpersonnel) {
                    this.relevantpersonnelOriginal = relevantpersonnel;
                    this.relevantpersonnel = JSON.parse(JSON.stringify(relevantpersonnel));
                }
                else {
                    this.relevantpersonnel = {};
                }
                this.relevantpersonnelDialogVisible = true;
            },
            async saveRelevantPersonnel() {
                if (this.relevantpersonnel.id == undefined) {
                    let relevantpersonnel = {
                        id: 'ok',
                        name: this.relevantpersonnel.name || '',
                        idCard: this.relevantpersonnel.idCard || '',
                        relation: this.relevantpersonnel.relation || '',
                        remark: this.relevantpersonnel.remark || '',
                    };
                    this.relevantpersonnels.push(relevantpersonnel);

                    this.relevantpersonnelDialogVisible = false;
                    this.$emit('input', this.relevantpersonnels);
                    this.$emit('change', this.relevantpersonnels);
                }
                else {
                    this.relevantpersonnelOriginal.id = 'ok';
                    this.relevantpersonnelOriginal.name = this.relevantpersonnel.name || '';
                    this.relevantpersonnelOriginal.idCard = this.relevantpersonnel.idCard || '';
                    this.relevantpersonnelOriginal.relation = this.relevantpersonnel.relation || '';
                    this.relevantpersonnelOriginal.remark = this.relevantpersonnel.remark || '';

                    this.relevantpersonnelDialogVisible = false;
                    this.$emit('input', this.relevantpersonnels);
                    this.$emit('change', this.relevantpersonnels);
                }
            },
            async deleteRelevantPersonnel(relevantpersonnel) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                let index = this.relevantpersonnels.indexOf(relevantpersonnel);
                this.relevantpersonnels.splice(index, 1);
                this.$emit('input', this.relevantpersonnels);
                this.$emit('change', this.relevantpersonnels);
            },
        },
    };
</script>