<template>
    <div></div>
</template>

<script>
    export default {
        methods: {
            async loadCurrentUser() {
                let response = await this.$axios.get('/api/Account/GetCurrentUser');
                if (response.data) {
                    this.$store.dispatch('setUser', response.data);

                    await this.loadDataDefinitions();
                    await this.loadCompanies();

                    if (this.$route.query.redirect) {
                        this.$router.replace(this.$route.query.redirect);
                    }
                    else {
                        this.$router.replace('/');
                    }
                }
                else {
                    this.$router.replace({ path: '/login', query: { redirect: this.$route.query.redirect } });
                }
            },
            async loadDataDefinitions() {
                let response = await this.$axios.get('/api/DataDefinition/GetDataDefinitions');
                this.$store.dispatch('setDataDefinitions', response.data);
            },
            async loadCompanies() {
                let response = await this.$axios.get('/api/Organization/GetCompanies');
                let companies = response.data;
                this.$store.dispatch('setCompanies', companies);

                if (companies.length != 0) {
                    let localStorageCompanies = companies.filter(p => p.id == localStorage.companyId);
                    if (localStorageCompanies.length == 0) {
                        this.$store.dispatch('setCompanyId', companies[0].id);
                        this.$store.dispatch('setCompanyName', companies[0].name);
                    }
                    else {
                        this.$store.dispatch('setCompanyId', localStorageCompanies[0].id);
                        this.$store.dispatch('setCompanyName', localStorageCompanies[0].name);
                    }
                }
            },

        },
        created() {
            document.title = '登录 - ' + this.$store.state.applicationName;

            if (!localStorage.token) {
                this.$router.replace({ path: '/login', query: { redirect: this.$route.query.redirect } });
                return;
            }

            this.loadCurrentUser();
        },
    };
</script>