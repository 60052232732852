import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import menu from '../menu';
import Screen from '../views/Screen/Index.vue';
import Admin from '../views/Admin/Index.vue';
import Main from '../views/Admin/Main.vue';
import Login from '../views/Admin/Login.vue';
import Initialize from '../views/Admin/Initialize.vue';
import PageNotFound from '../views/Shared/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        meta: { requiresAuth: true },
        component: Screen,
    },
    {
        path: '/admin',
        meta: { requiresAuth: true },
        component: Admin,
    },
    {
        path: '/lm',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getLandManagementRoutes()]
    },
    {
        path: '/fm',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getFarmerManagementRoutes()]
    },
    {
        path: '/lt',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getLandTransferRoutes()]
    },
    {
        path: '/pp',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getPlantPredictRoutes()]
    },
    {
        path: '/pm',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getProductManagementRoutes()]
    },
    {
        path: '/cm',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getCropMonitoringRoutes()]
    },
    {
        path: '/ia',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getIdentityAuthenticationRoutes()]
    },
    {
        path: '/mm',
        meta: { requiresAuth: true },
        component: () => import('../views/Admin/Main_MM.vue'),
        children: [...menu.getMallManagementRoutes()]
    },
    {
        path: '/lad',
        meta: { requiresAuth: true },
        component: Main,
        children: [...menu.getLandAutoDeviceRoutes()]
    },
    {
        path: '/screen/ua',
        meta: { requiresAuth: true },
        component: () => import('../views/Screen/UserAnalysis.vue')
    },
    {
        path: '/audit-flow/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/AuditFlow/Detail.vue')
    },
    {
        path: '/registered-user/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/RegisteredUser/Detail.vue')
    },
    {
        path: '/land-contract/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/LandContract/Detail.vue')
    },
    {
        path: '/land/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Land/Detail.vue')
    },
    {
        path: '/product/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Product/Detail.vue')
    },
    {
        path: '/order/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Order/Detail.vue')
    },
    {
        path: '/article/view',
        component: () => import('../views/Article/View.vue')
    },
    {
        path: '/plant-contract/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/PlantContract/Detail.vue')
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/initialize',
        component: Initialize
    },
    {
        path: '*',
        component: PageNotFound
    },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (localStorage.token) {
            if (store.state.user) {
                next();
            }
            else {
                next({ path: '/initialize', query: { redirect: to.fullPath } });
            }
        }
        else {
            next({ path: '/login', query: { redirect: to.fullPath } });
        }
    }
    else {
        next();
    }
});

export default router;