import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        applicationName: '智慧农业系统一体化平台',
        company: '新疆讯通网络工程有限公司',
        user: null,
        dataDefinitions: {},
        companies: [],
        companyId: null,
        companyName: null,
        showStartVideo: true,
        defaultMapCenter: [87.61344, 43.826328],
    },
    getters: {
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setDataDefinitions(state, dataDefinitions) {
            state.dataDefinitions = dataDefinitions;
        },
        setCompanies(state, companies) {
            state.companies = companies;
        },
        setCompanyId(state, companyId) {
            state.companyId = companyId;
        },
        setCompanyName(state, companyName) {
            state.companyName = companyName;
        },
        setShowStartVideo(state, showStartVideo) {
            state.showStartVideo = showStartVideo;
        },
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        setDataDefinitions(context, dataDefinitions) {
            context.commit('setDataDefinitions', dataDefinitions);
        },
        setCompanies(context, companies) {
            context.commit('setCompanies', companies);
        },
        setCompanyId(context, companyId) {
            context.commit('setCompanyId', companyId);
        },
        setCompanyName(context, companyName) {
            context.commit('setCompanyName', companyName);
        },
        setShowStartVideo(context, showStartVideo) {
            context.commit('setShowStartVideo', showStartVideo);
        },
    },
    modules: {
    },
});