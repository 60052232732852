export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/lm', component: () => import('../views/Admin/Home.vue') },
        ]
    },
    {
        name: '土地管理',
        expanded: true,
        menuItems: [
            { permission: '/lm/land', name: '水发现有土地', icon: 'kaaba', path: '/lm/land', component: () => import('../views/Land/Index.vue') },
            { permission: '/lm/land/warning', name: '快到期的土地', icon: 'kaaba', path: '/lm/land/warning', component: () => import('../views/Land/Warning.vue') },
            { permission: '/lm/land/mapping', name: '土地测绘', icon: 'kaaba', path: '/lm/land/mapping', component: () => import('../views/Land/Mapping.vue') },
        ]
    },
];