<template>
    <el-dialog :visible.sync="visible" title="选择担保人" :close-on-click-modal="false" append-to-body width="640px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 400px;">
            <el-table :data="warrantors">
                <el-table-column prop="registeredUser.name" label="姓名" sortable />
                <el-table-column prop="registeredUser.idCard" label="身份证号" />
                <el-table-column prop="registeredUser.phone" label="电话" />
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectWarrantor(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            farmerId: { type: String },
        },
        data() {
            return {
                visible: this.value,
                warrantors: [],
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadWarrantors();
                }
            },
        },
        methods: {
            async loadWarrantors() {
                let response = await this.$axios.get('/api/Warrantor/GetFarmerWarrantors', {
                    params: { farmerId: this.farmerId }
                });
                this.warrantors = response.data;
            },
            selectWarrantor(warrantor) {
                this.$emit('select', warrantor);
                this.$emit('input', false);
            },
        },
    };
</script>