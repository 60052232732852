<template>
    <router-view />
</template>

<script>
    export default {
        created() {
            document.title = this.$store.state.applicationName;

            if (!document.cookie) {
                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
            }
        },
    };
</script>