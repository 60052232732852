<template>
    <el-dialog :visible.sync="visible" title="选择种植户" :close-on-click-modal="false" append-to-body width="960px" top="0"
        @closed="$emit('input',false)">
        <div class="row no-gutters">
            <div class="col">
                <search-box v-model="key" style="width: 280px;" @search="loadFarmers(true)" />
            </div>
            <div class="col-auto">
                <el-radio-group v-model="isEnterprise" @change="loadFarmers(true)">
                    <el-radio-button :label="false">个人</el-radio-button>
                    <el-radio-button :label="true">企业</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="narrow-scrollbar border rounded overflow-auto mt-2" style="height: calc(100vh - 270px);">
            <el-table :data="farmers.data">
                <el-table-column prop="registeredUser.name" :label="nameText" sortable />
                <el-table-column prop="registeredUser.idCard" :label="idCardText" />
                <el-table-column v-if="isEnterprise" label="法人">
                    <template #default="scope">
                        <div>{{scope.row.legalPersonName}}</div>
                        <div>{{scope.row.legalPersonPhone}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="联系人">
                    <template #default="scope">
                        <div>{{scope.row.contactName}}</div>
                        <div>{{scope.row.contactPhone}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectFarmer(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination layout="total,->,prev,pager,next" :total="farmers.totalRecords"
            :current-page="farmersPagination.currentPage" :page-size="farmersPagination.pageSize" class="mt-3"
            @current-change="farmersPaginationCurrentChange" />
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                isEnterprise: false,
                key: null,
                farmers: {},
                farmersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        computed: {
            nameText() {
                return this.isEnterprise ? '企业名称' : '姓名';
            },
            idCardText() {
                return this.isEnterprise ? '统一社会信用代码' : '身份证号';
            },
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadFarmers();
                }
            },
        },
        methods: {
            async loadFarmers(resetPage = false) {
                if (resetPage) {
                    this.farmersPagination.currentPage = 1;
                }

                if (this.isEnterprise) {
                    let response = await this.$axios.get('/api/Farmer/GetEnterpriseFarmers', {
                        params: {
                            companyId: this.$store.state.companyId,
                            status: 3,
                            key: this.key,
                            pageIndex: this.farmersPagination.currentPage - 1,
                            pageSize: this.farmersPagination.pageSize,
                        }
                    });
                    this.farmers = response.data;
                }
                else {
                    let response = await this.$axios.get('/api/Farmer/GetIndividualFarmers', {
                        params: {
                            companyId: this.$store.state.companyId,
                            status: 3,
                            key: this.key,
                            pageIndex: this.farmersPagination.currentPage - 1,
                            pageSize: this.farmersPagination.pageSize,
                        }
                    });
                    this.farmers = response.data;
                }
            },
            farmersPaginationCurrentChange(page) {
                this.farmersPagination.currentPage = page;
                this.loadFarmers();
            },
            selectFarmer(farmer) {
                this.$emit('select', farmer);
                this.$emit('input', false);
            },
        },
    };
</script>