export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/pp', component: () => import('../views/Admin/Home.vue') },
        ]
    },
    {
        name: '天地空一体化遥感监测',
        expanded: true,
        menuItems: [
            { permission: '', name: '卫星遥感监测', icon: 'satellite', path: '/pp/gis', component: () => import('../views/RemoteSense/Index.vue') },
            { permission: '', name: '无人机遥感监测', icon: 'satellite', path: '/pp/gis2', component: () => import('../views/RemoteSense/Index2.vue') },
        ]
    },
    {
        name: '田间农事',
        expanded: true,
        menuItems: [
            { permission: '', name: '耕', icon: 'snowplow', path: '/pp/farmwork/1', component: () => import('../views/Farmwork/Index1.vue') },
            { permission: '', name: '种', icon: 'spa', path: '/pp/farmwork/2', component: () => import('../views/Farmwork/Index2.vue') },
            { permission: '', name: '管', icon: 'spray-can', path: '/pp/farmwork/3', component: () => import('../views/Farmwork/Index3.vue') },
            { permission: '', name: '收', icon: 'hand-holding-water', path: '/pp/farmwork/4', component: () => import('../views/Farmwork/Index4.vue') },
        ]
    },
    {
        name: '专家库',
        expanded: true,
        menuItems: [
            { permission: '', name: '专家申请审核', icon: 'check-double', path: '/pp/expert/audit', component: () => import('../views/Expert/Audit.vue') },
            { permission: '', name: '专家管理', icon: 'user-graduate', path: '/pp/expert', component: () => import('../views/Expert/Index.vue') },
        ]
    },
    {
        name: '基础设置',
        expanded: true,
        menuItems: [
            { permission: '', name: '农事进度设置', icon: 'seedling', path: '/pp/crop/schedule', component: () => import('../views/Crop/Schedule.vue') },
            { permission: '', name: '作物品种设置', icon: 'seedling', path: '/pp/crop/variety', component: () => import('../views/Crop/Variety.vue') },
            { permission: '', name: '职业技能', icon: 'ticket-alt', path: '/pp/professional-skill', component: () => import('../views/ProfessionalSkill/Index.vue') },
        ]
    },
];
