export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/pm', component: () => import('../views/Admin/Home.vue') },
        ]
    },
    {
        name: '供应商管理',
        expanded: true,
        menuItems: [
            { permission: '/pm/supplier/audit-individual', name: '个人供应商准入审核', icon: 'check-double', path: '/pm/supplier/audit-individual', component: () => import('../views/Supplier/AuditIndividual.vue') },
            { permission: '/pm/supplier/audit-enterprise', name: '企业供应商准入审核', icon: 'check-double', path: '/pm/supplier/audit-enterprise', component: () => import('../views/Supplier/AuditEnterprise.vue') },
            { permission: '/pm/supplier/accessed-individual', name: '已准入个人供应商', icon: 'truck', path: '/pm/supplier/accessed-individual', component: () => import('../views/Supplier/AccessedIndividual.vue') },
            { permission: '/pm/supplier/accessed-enterprise', name: '已准入企业供应商', icon: 'truck', path: '/pm/supplier/accessed-enterprise', component: () => import('../views/Supplier/AccessedEnterprise.vue') },
        ]
    },
    {
        name: '商品管理',
        expanded: true,
        menuItems: [
            { permission: '/pm/product-type', name: '商品类型', icon: 'boxes', path: '/pm/product-type', component: () => import('../views/ProductType/Index.vue') },
            { permission: '/pm/product/audit', name: '商品审核', icon: 'check-double', path: '/pm/product/audit', component: () => import('../views/Product/Audit.vue') },
            { permission: '/pm/product/published', name: '发布的商品', icon: 'box', path: '/pm/product/published', component: () => import('../views/Product/Published.vue') },
        ]
    },
    {
        name: '订单管理',
        expanded: true,
        menuItems: [
            { permission: '/pm/order/audit', name: '超额订单审核', icon: 'check-double', path: '/pm/order/audit', component: () => import('../views/Order/Audit.vue') },
            { permission: '/pm/supplier/settle', name: '供应商结算', icon: 'clipboard-list', path: '/pm/supplier/settle', component: () => import('../views/Settlement/Settle.vue') },
            { permission: '/pm/settlement', name: '结算单', icon: 'clipboard-check', path: '/pm/settlement', component: () => import('../views/Settlement/Index.vue') },
        ]
    },
];