<template>
    <div class="d-flex justify-content-center align-items-center text-center" style="height: 50vh;">
        <div>
            <font-awesome-icon icon="times-circle" style="font-size: 96px; color: #dddddd;" />
            <div class="mt-4 text-info">404 页面未找到</div>
        </div>
    </div>
</template>

<script>
    export default {
        created() {
            document.title = '404 页面未找到';
        },
    };
</script>