<template>
    <div class="el-input">
        <tinymce-vue v-model="value2" :init="init" :disabled="disabled" />
    </div>
</template>

<script>
    import tinymce from 'tinymce/tinymce';
    import TinymceVue from '@tinymce/tinymce-vue';
    import 'tinymce/themes/silver';
    import 'tinymce/icons/default'
    import 'tinymce/plugins/image'; // 上传图片插件
    import 'tinymce/plugins/imagetools'; // 图片工具插件
    import 'tinymce/plugins/table'; // 表格插件
    import 'tinymce/plugins/lists'; // 列表插件
    import 'tinymce/plugins/link'; // 链接插件
    import 'tinymce/plugins/fullscreen'; // 全屏插件
    import 'tinymce/plugins/anchor'; // 锚链接插件
    import 'tinymce/plugins/autoresize'; // 自适应插件

    export default {
        props: {
            value: { type: String, default: '' },
            placeholder: { type: String, default: null },
            minHeight: { type: Number, default: 0 },
            maxHeight: { type: Number, default: 0 },
            menubar: { type: Boolean, default: false },
            statusbar: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            toolbar: {
                type: [String, Array],
                default: 'bold italic forecolor backcolor | styleselect | bullist numlist | link image | removeformat | fullscreen'
                // 默认：
                // 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent'
                // 极简（文字）：
                // 'bold italic | formatselect | bullist numlist | removeformat'
                // 简单（文字/链接/图片）：
                // 'bold italic forecolor backcolor | styleselect | bullist numlist | link image | removeformat | fullscreen'
            },
            plugins: { type: [String, Array], default: 'lists image imagetools link fullscreen anchor autoresize' },
        },
        data() {
            return {
                value2: this.value,
                init: {
                    language_url: 'tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: 'tinymce/skins/ui/oxide',
                    // skin_url: 'tinymce/skins/ui/oxide-dark',
                    content_css: 'tinymce/skins/content/default/content.min.css',
                    // content_css: 'tinymce/skins/content/dark/content.min.css', 
                    placeholder: this.placeholder,
                    min_height: this.minHeight,
                    max_height: this.maxHeight,
                    menubar: this.menubar,
                    statusbar: this.statusbar,
                    toolbar: this.toolbar,
                    plugins: this.plugins,
                    branding: false,
                    images_upload_handler: (blobInfo, success, failure) => {
                        let formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        this.$axios.create().post('/api/File/Upload', formData, {
                            headers: { Authorization: 'Bearer ' + localStorage.token }
                        }).then(response => {
                            success('/' + response.data[0].fileUrl);
                        }).catch(() => {
                            failure('无法上传图片');
                        });
                    },
                },
            };
        },
        components: { TinymceVue },
        watch: {
            value(value) {
                this.value2 = value;
            },
            value2(value) {
                this.$emit('input', value);
            },
        },
        mounted() {
            tinymce.init({});
        },
    };
</script>

<style>
    .tox-tinymce {
        border: 1px solid #dcdfe6 !important;
        border-radius: 4px !important;
    }

    .tox .tox-statusbar {
        border-top: 1px solid #dcdfe6 !important;
    }

    .tox .tox-menubar+.tox-toolbar,
    .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary {
        border-top: 1px solid #dcdfe6 !important;
    }

    .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
        border-right: 1px solid #dcdfe6 !important;
    }

    .tox-tinymce-aux {
        z-index: 10000 !important;
    }
</style>