export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '我的基本信息', icon: 'address-card', path: '/mm', component: () => import('../views/Supplier/Me.vue') },
        ]
    },
    {
        name: '我的订单',
        expanded: true,
        menuItems: [
            { permission: '', name: '订单', icon: 'file-invoice', path: '/mm/order/supplier', component: () => import('../views/Order/Supplier.vue') },
            { permission: '', name: '待结算订单项', icon: 'clipboard', path: '/mm/settlement/no-settled-order-item', component: () => import('../views/Settlement/NoSettledOrderItem.vue') },
            { permission: '', name: '我的结算单', icon: 'clipboard-check', path: '/mm/settlement/my', component: () => import('../views/Settlement/My.vue') },
        ]
    },
    {
        name: '商品及库存',
        expanded: true,
        menuItems: [
            { permission: '', name: '商品', icon: 'box', path: '/mm/product', component: () => import('../views/Product/Index.vue') },
            { permission: '', name: '库存', icon: 'box-open', path: '/mm/product/stock', component: () => import('../views/Product/Stock.vue') },
            // { permission: '', name: '进货入库', icon: 'sign-in-alt', path: '/mm/stock/stock', component: () => import('../views/Stock/Stock.vue') },
            // { permission: '', name: '进货退货', icon: 'reply-all', path: '/mm/stock/stock-return', component: () => import('../views/Stock/StockReturn.vue') },
            // { permission: '', name: '销售出库', icon: 'sign-out-alt', path: '/mm/stock/sell', component: () => import('../views/Stock/Sell.vue') },
            // { permission: '', name: '销售退货', icon: 'reply-all', path: '/mm/stock/sell-return', component: () => import('../views/Stock/SellReturn.vue') },
        ]
    },
];