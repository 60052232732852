<template>
    <div>
        <el-dialog :visible.sync="visible" title="选择土地" :close-on-click-modal="false" append-to-body width="960px"
            top="0" @closed="$emit('input',false)">
            <div class="narrow-scrollbar overflow-auto mt-2" style="height: calc(100vh - 240px);">
                <el-collapse>
                    <el-collapse-item v-for="plantContract in plantContracts.data" :key="plantContract.id">
                        <template slot="title">
                            <div>
                                <span class="font-weight-bold">
                                    {{plantContract.year}} 年
                                    |
                                    {{plantContract.name}}
                                </span>
                                <span class="text-info ml-2">{{plantContract.farmerName}}</span>
                            </div>
                        </template>
                        <el-table :data="plantContract.plantContractLands" border>
                            <el-table-column prop="landName" label="土地" />
                            <el-table-column prop="landArea" label="土地面积" width="100" />
                            <el-table-column label="操作" fixed="right" width="90">
                                <template #default="scope">
                                    <el-button type="primary" size="small" @click="selectPlantContractLand(scope.row)">
                                        选择
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
            <template #footer>
                <el-button @click="$emit('input',false)">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadPlantContracts();
                }
            },
        },
        methods: {
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 5,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            selectPlantContractLand(plantContractLand) {
                this.$emit('select', plantContractLand);
                this.$emit('input', false);
            },
        },
    };
</script>