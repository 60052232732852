<template>
    <el-dialog :visible.sync="visible" title="选择行政区划" :close-on-click-modal="false" append-to-body width="400px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 400px;">
            <el-tree :data="regionTreeNodes" node-key="value" highlight-current
                :default-expanded-keys="defaultExpandedKeys" :expand-on-click-node="false"
                @current-change="selectRegionTreeNode" />
        </div>
        <template #footer>
            <el-button type="primary" @click="selectRegion">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                regionTreeNodes: [],
                regionId: null,
                regionName: null,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.regionId = null;
                    this.regionName = null;
                    this.loadRegionTreeNodes();
                }
            },
        },
        computed: {
            defaultExpandedKeys() {
                return this.regionTreeNodes.map(p => p.value);
            },
        },
        methods: {
            async loadRegionTreeNodes() {
                let response = await this.$axios.get('/api/Region/GetRegionTreeNodes');
                this.regionTreeNodes = response.data;
            },
            selectRegionTreeNode(data) {
                this.regionId = data.value;
                this.regionName = data.label;
            },
            selectRegion() {
                if (this.regionId == null) {
                    return;
                }

                this.$emit('select', { id: this.regionId, name: this.regionName });
                this.$emit('input', false);
            },
        },
    };
</script>