export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/ia', component: () => import('../views/Admin/Home_IA.vue') },
        ]
    },
    {
        name: '通知',
        expanded: true,
        menuItems: [
            { permission: 'ArticleCategory', name: '通知分类', icon: 'stream', path: '/ia/article-category', component: () => import('../views/ArticleCategory/Index.vue') },
            { permission: 'Article', name: '通知', icon: 'newspaper', path: '/ia/article', component: () => import('../views/Article/Index.vue') },
        ]
    },
    {
        name: '风险预警',
        expanded: true,
        menuItems: [
            { permission: '/ia/registered-user/risky', name: '个人用户风险预警', icon: 'user-shield', path: '/ia/registered-user/risky-individual', component: () => import('../views/RegisteredUser/RiskyIndividual.vue') },
            { permission: '/ia/registered-user/risky', name: '企业用户风险预警', icon: 'user-shield', path: '/ia/registered-user/risky-enterprise', component: () => import('../views/RegisteredUser/RiskyEnterprise.vue') },
        ]
    },
    {
        name: '用户认证管理平台',
        expanded: true,
        menuItems: [
            { permission: 'Organization', name: '水发组织机构', icon: 'sitemap', path: '/ia/organization', component: () => import('../views/Organization/Index.vue') },
            { permission: 'User', name: '水发管理用户', icon: 'user', path: '/ia/user', component: () => import('../views/User/Index.vue') },
            { permission: '/ia/registered-user', name: '注册用户管理', icon: 'user-plus', path: '/ia/registered-user', component: () => import('../views/RegisteredUser/Index.vue') },
            { permission: '/ia/registered-user/audit', name: '个人用户实名审核', icon: 'check-double', path: '/ia/registered-user/audit-individual', component: () => import('../views/RegisteredUser/AuditIndividual.vue') },
            { permission: '/ia/registered-user/audit', name: '企业用户实名审核', icon: 'check-double', path: '/ia/registered-user/audit-enterprise', component: () => import('../views/RegisteredUser/AuditEnterprise.vue') },
            { permission: '/ia/registered-user/authenticated', name: '已实名个人用户', icon: 'user-check', path: '/ia/registered-user/authenticated-individual', component: () => import('../views/RegisteredUser/AuthenticatedIndividual.vue') },
            { permission: '/ia/registered-user/authenticated', name: '已实名企业用户', icon: 'user-check', path: '/ia/registered-user/authenticated-enterprise', component: () => import('../views/RegisteredUser/AuthenticatedEnterprise.vue') },
            { permission: '/ia/registered-user/disabled', name: '已禁用的用户', icon: 'user-times', path: '/ia/registered-user/disabled', component: () => import('../views/RegisteredUser/Disabled.vue') },
            { permission: 'Role', name: '角色', icon: 'users', path: '/ia/role', component: () => import('../views/Role/Index.vue') },
            { permission: 'SystemLog', name: '系统日志', icon: 'file-alt', path: '/ia/system-log', component: () => import('../views/SystemLog/Index.vue') },
        ]
    },
];