export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/lt', component: () => import('../views/Admin/Home.vue') },
            { permission: '/lt/todo', name: '履约', icon: 'clipboard-list', path: '/lt/todo', component: () => import('../views/ToDo/Index.vue') },
        ]
    },
    {
        name: '网上土地流入',
        expanded: true,
        menuItems: [
            { permission: '/lt/partner', name: '土地来源方', icon: 'user-friends', path: '/lt/partner', component: () => import('../views/Partner/Index.vue') },
            { permission: '/lt/land-contract/draft', name: '土地合同起草', icon: 'scroll', path: '/lt/land-contract/draft', component: () => import('../views/LandContract/Draft.vue') },
            { permission: '/lt/land-contract/audit', name: '土地合同审核', icon: 'check-double', path: '/lt/land-contract/audit', component: () => import('../views/LandContract/Audit.vue') },
            { permission: '/lt/land-contract/sign', name: '土地合同签约', icon: 'file-signature', path: '/lt/land-contract/sign', component: () => import('../views/LandContract/Sign.vue') },
            { permission: '/lt/land-contract/progress', name: '线上签约进度', icon: 'circle-notch', path: '/lt/land-contract/progress', component: () => import('../views/LandContract/Progress.vue') },
            { permission: '/lt/land-contract/signed', name: '已签约的土地合同', icon: 'vote-yea', path: '/lt/land-contract/signed', component: () => import('../views/LandContract/Signed.vue') },
            { permission: '/lt/land-contract/archived', name: '已归档的土地合同', icon: 'history', path: '/lt/land-contract/archived', component: () => import('../views/LandContract/Archived.vue') },
        ]
    },
    {
        name: '种植合同',
        expanded: true,
        menuItems: [
            { permission: '/lt/plant-contract/draft', name: '种植合同起草', icon: 'scroll', path: '/lt/plant-contract/draft', component: () => import('../views/PlantContract/Draft.vue') },
            { permission: '/lt/plant-contract/audit', name: '种植合同审核', icon: 'check-double', path: '/lt/plant-contract/audit', component: () => import('../views/PlantContract/Audit.vue') },
            { permission: '/lt/plant-contract/sign', name: '种植合同签约', icon: 'file-signature', path: '/lt/plant-contract/sign', component: () => import('../views/PlantContract/Sign.vue') },
            { permission: '/lt/plant-contract/progress', name: '线上签约进度', icon: 'file-signature', path: '/lt/plant-contract/progress', component: () => import('../views/PlantContract/Progress.vue') },
            { permission: '/lt/plant-contract/signed', name: '已签约的种植合同', icon: 'vote-yea', path: '/lt/plant-contract/signed', component: () => import('../views/PlantContract/Signed.vue') },
            //{ permission: '/lt/plant-contract/settle', name: '待结算的种植合同*', icon: 'money-check', path: '/lt/plant-contract/settle', component: () => import('../views/PlantContract/Settle.vue') },
            //{ permission: '/lt/plant-contract/settled', name: '已结算的种植合同', icon: 'money-check-alt', path: '/lt/plant-contract/settled', component: () => import('../views/PlantContract/Settled.vue') },
            { permission: '/lt/plant-contract/archived', name: '已归档的种植合同', icon: 'history', path: '/lt/plant-contract/archived', component: () => import('../views/PlantContract/Archived.vue') },
        ]
    },
];