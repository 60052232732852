import LandManagementMenus from './LandManagementMenus';
import FarmerManagementMenus from './FarmerManagementMenus';
import LandTransferMenus from './LandTransferMenus';
import PlantPredictMenus from './PlantPredictMenus';
import ProductManagementMenus from './ProductManagementMenus';
import IdentityAuthenticationMenus from './IdentityAuthenticationMenus';
import MallManagementMenus from './MallManagementMenus';
import CropMonitoringMenus from './CropMonitoringMenus';
import LandAutoDeviceMenus from './LandAutoDeviceMenus';

function getLandManagementRoutes() {
    let result = [];
    for (const menuGroup of LandManagementMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getFarmerManagementRoutes() {
    let result = [];
    for (const menuGroup of FarmerManagementMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getLandTransferRoutes() {
    let result = [];
    for (const menuGroup of LandTransferMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getPlantPredictRoutes() {
    let result = [];
    for (const menuGroup of PlantPredictMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getProductManagementRoutes() {
    let result = [];
    for (const menuGroup of ProductManagementMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getIdentityAuthenticationRoutes() {
    let result = [];
    for (const menuGroup of IdentityAuthenticationMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getMallManagementRoutes() {
    let result = [];
    for (const menuGroup of MallManagementMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getCropMonitoringRoutes() {
    let result = [];
    for (const menuGroup of CropMonitoringMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getLandAutoDeviceRoutes() {
    let result = [];
    for (const menuGroup of LandAutoDeviceMenus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getLandManagementMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of LandManagementMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getFarmerManagementMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of FarmerManagementMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getLandTransferMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of LandTransferMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getPlantPredictMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of PlantPredictMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getProductManagementMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of ProductManagementMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getIdentityAuthenticationMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of IdentityAuthenticationMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getMallManagementMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of MallManagementMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getCropMonitoringMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of CropMonitoringMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

function getLandAutoDeviceMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of LandAutoDeviceMenus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

export default {
    getLandManagementRoutes,
    getFarmerManagementRoutes,
    getLandTransferRoutes,
    getPlantPredictRoutes,
    getProductManagementRoutes,
    getIdentityAuthenticationRoutes,
    getMallManagementRoutes,
    getCropMonitoringRoutes,
    getLandAutoDeviceRoutes,

    getLandManagementMenus,
    getFarmerManagementMenus,
    getLandTransferMenus,
    getPlantPredictMenus,
    getProductManagementMenus,
    getIdentityAuthenticationMenus,
    getMallManagementMenus,
    getCropMonitoringMenus,
    getLandAutoDeviceMenus,
};