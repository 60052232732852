export default [
    {
        name: '',
        expanded: true,
        menuItems: [{
            permission: '', name: '首页', icon: 'home', path: '/lad', component: () => import('../views/Admin/Home.vue')
        },
        ]
    },
    {
        name: '设备管理',
        expanded: true,
        menuItems: [
            { permission: '', name: '设备绑定', icon: 'hdd', path: '/lad/land-device', component: () => import('../views/LandDevice/Tdmap.vue') },
            { permission: '', name: '气象监测', icon: 'satellite-dish', path: '/lad/weather', component: () => import('../views/WeatherStation/handle.vue') },
            { permission: '*', name: '气象监测', icon: 'satellite-dish', path: '/lad/weatherDetail', component: () => import('../views/WeatherStation/index.vue') },
            { permission: '', name: '墒情监测', icon: 'band-aid', path: '/lad/landInfo', component: () => import('../views/LandSensor/index.vue') },
            { permission: '', name: '虫情监测', icon: 'bug', path: '/lad/insect', component: () => import('../views/InsectCheck/handle.vue') },
            { permission: '*', name: '虫情监测', icon: 'bug', path: '/lad/insectDetail', component: () => import('../views/InsectCheck/index.vue') },
            { permission: '', name: '施肥控制', icon: 'prescription-bottle', path: '/lad/deliver', component: () => import('../views/DeliverShit/index.vue') },
            { permission: '*', name: '施肥控制', icon: 'prescription-bottle', path: '/lad/controller', component: () => import('../views/DeliverShit/controller.vue') },
        ]
    },
];