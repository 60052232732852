<template>
    <div class="login-background">
        <div class="login-panel">
            <div class="text-center">
                <img src="@/assets/logo.png" style="height: 64px;" />
            </div>
            <div class="text-center text-white">
                <h3>{{$store.state.applicationName}}</h3>
            </div>
            <div class="mt-4">
                <el-form>
                    <el-form-item>
                        <el-input v-model="userName" prefix-icon="el-icon-user" placeholder="用户名"
                            @keyup.enter.native="login" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="password" prefix-icon="el-icon-key" placeholder="密码"
                            @keyup.enter.native="login" />
                    </el-form-item>
                </el-form>
                <el-button type="primary" round class="w-100 mt-4" @click="login">登录</el-button>
            </div>
            <div class="text-white mt-4 text-center o-50">{{$store.state.company}}</div>
        </div>
        <div class="position-fixed" style="right: 16px; bottom: 16px;">
            <a href="/google chrome_x64.exe">谷歌浏览器 64位</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userName: null,
                password: null,
            };
        },
        methods: {
            async login() {
                let response = await this.$axios.post('/api/Account/Login', {
                    userName: this.userName,
                    password: this.password,
                });
                document.cookie = 'login=true';
                localStorage.token = response.data;

                let user = await this.loadCurrentUser();
                if (user.type == 1) {
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect).catch(() => { });
                    }
                    else {
                        this.$router.push('/').catch(() => { });
                    }
                }
                else {
                    this.loadSupplier(user);
                }
            },
            async loadCurrentUser() {
                let response = await this.$axios.get('/api/Account/GetCurrentUser');
                return response.data;
            },
            async loadSupplier(user) {
                let response = await this.$axios.get('/api/Supplier/GetSupplier', { params: { id: user.id } });
                let supplier = response.data;

                if (supplier.id) {
                    this.$router.replace('/mm');
                }
                else {
                    alert('您尚未注册供应商');
                }
            },
        },
        created() {
            document.title = '登录 - ' + this.$store.state.applicationName;
        },
    };
</script>

<style>
    .login-background {
        width: 100vw;
        height: 100vh;
        background-image: url('../../assets/background.jpg');
        background-position: center;
    }

    .login-panel {
        background-color: #ffffff1f;
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        padding: 3rem;
    }

    @media (min-width: 576px) {
        .login-panel {
            height: auto !important;
            max-width: 400px;
            position: relative;
            top: 10vh;
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
        }
    }
</style>