export default [
    {
        name: '',
        expanded: true,
        menuItems: [
            { permission: '', name: '首页', icon: 'home', path: '/fm', component: () => import('../views/Admin/Home.vue') },
        ]
    },
    {
        name: '种植户管理',
        expanded: true,
        menuItems: [
            { permission: '/fm/plant-contract/signed', name: '已签约种植合同', icon: 'vote-yea', path: '/fm/plant-contract/signed', component: () => import('../views/PlantContract/Signed.vue') },
            { permission: '/fm/plant-insurance', name: '种植保险', icon: 'first-aid', path: '/fm/plant-insurance', component: () => import('../views/PlantInsurance/Index.vue') },
            { permission: '/fm/plant-point-card', name: '种植积分', icon: 'coins', path: '/fm/plant-point-card', component: () => import('../views/PlantPointCard/Index.vue') },
            { permission: '/fm/plant-subsidy', name: '种植补贴', icon: 'money-bill', path: '/fm/plant-subsidy', component: () => import('../views/PlantSubsidy/Index.vue') },
        ]
    },
    {
        name: '种植户准入',
        expanded: true,
        menuItems: [
            { permission: '/fm/farmer/audit-individual', name: '个人种植户准入审核', icon: 'check-double', path: '/fm/farmer/audit-individual', component: () => import('../views/Farmer/AuditIndividual.vue') },
            { permission: '/fm/farmer/audit-enterprise', name: '企业种植户准入审核', icon: 'check-double', path: '/fm/farmer/audit-enterprise', component: () => import('../views/Farmer/AuditEnterprise.vue') },
            { permission: '/fm/farmer/accessed-individual', name: '已准入个人种植户', icon: 'street-view', path: '/fm/farmer/accessed-individual', component: () => import('../views/Farmer/AccessedIndividual.vue') },
            { permission: '/fm/farmer/accessed-enterprise', name: '已准入企业种植户', icon: 'street-view', path: '/fm/farmer/accessed-enterprise', component: () => import('../views/Farmer/AccessedEnterprise.vue') },
        ]
    },
    {
        name: '担保人准入',
        expanded: true,
        menuItems: [
            { permission: '/fm/warrantor/audit', name: '担保人认证审核', icon: 'check-double', path: '/fm/warrantor/audit', component: () => import('../views/Warrantor/Audit.vue') },
            { permission: '/fm/warrantor/authenticated', name: '已认证担保人', icon: 'user-tie', path: '/fm/warrantor/authenticated', component: () => import('../views/Warrantor/Authenticated.vue') },
        ]
    },
];