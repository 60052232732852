<template>
    <div>
        <div class="row no-gutters" style="margin: -2px;">
            <div v-for="picture in pictures" :key="picture.url"
                class="col-auto bg-light border rounded position-relative"
                style="width: 72px; height: 72px; margin: 2px;">
                <el-image :src="picture.url" :title="picture.name" :preview-src-list="pictures.map(p=>p.url)"
                    fit="cover" class="h-100 w-100" />
                <div v-if="!readonly" class="position-absolute" style="right: 4px; bottom: 4px;">
                    <el-button icon="el-icon-close" circle title="删除" class="text-white border-0 p-1"
                        style="background-color: red;" @click="deletePicture(picture)" />
                </div>
            </div>
            <div v-if="!readonly&&pictures.length<limit" class="col-auto bg-light border rounded"
                style="width: 72px; height: 72px; margin: 2px;">
                <file-uploader accept="image/*" :data="{width:pictureMaxWidth}" multiple @before-upload="beforeUpload"
                    @upload-success="uploadSuccess" @upload-error="uploadError">
                    <template #default>
                        <div class="text-center">
                            <i v-if="uploading" class="el-icon-loading text-info"
                                style="font-size: 32px; line-height: 70px;"></i>
                            <i v-else class="el-icon-plus text-info" style="font-size: 32px; line-height: 70px;"></i>
                        </div>
                    </template>
                </file-uploader>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Array, default: () => [] },
            readonly: { type: Boolean, default: false },
            pictureMaxWidth: { type: Number, default: 1280 },
            limit: { type: Number, default: 64 },
        },
        data() {
            return {
                pictures: this.value,
                uploading: false,
            };
        },
        watch: {
            value(value) {
                this.pictures = value;
            },
        },
        methods: {
            beforeUpload() {
                this.uploading = true;
            },
            uploadSuccess(file) {
                this.uploading = false;
                this.pictures.push({
                    name: file.originalFileName,
                    url: file.fileUrl,
                    extension: file.fileExtension,
                    size: file.fileSize,
                });
                this.$emit('input', this.pictures);
            },
            uploadError() {
                this.uploading = false;
                this.$message.error('上传失败！');
            },
            deletePicture(picture) {
                let index = this.pictures.indexOf(picture);
                this.pictures.splice(index, 1);
                this.$emit('input', this.pictures);
            },
        },
    };
</script>