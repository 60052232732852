<template>
    <div class="page-background">
        <div v-if="$store.state.user.type==1">
            <div class="vw-100 vh-100 d-flex align-items-center justify-content-center">
                <div>
                    <div class="row no-gutters mx-auto text-center" style="width: 960px;">
                        <div class="col-4">
                            <router-link to="/ia" class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="users" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        用户认证管理平台
                                        <!-- IdentityAuthentication -->
                                        <!-- ia -->
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-4">
                            <router-link to="/fm" class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="user-friends" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        种植户管理平台
                                        <!-- FarmerManagement -->
                                        <!-- fm -->
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-4">
                            <router-link to="/lt" class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="archive" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        网上土地流转系统
                                        <!-- LandTransfer -->
                                        <!-- lt -->
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-4">
                            <router-link to="/pm" class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="store" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        商城管理平台(供应链)
                                        <!-- ProductManagement -->
                                        <!-- pm -->
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-4">
                            <router-link v-if="$store.state.user.userName=='admin'" to="/lad"
                                class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="weight" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        田间农事自动化管控平台
                                        <!-- LandAutoDevice -->
                                        <!-- lad -->
                                    </div>
                                </div>
                            </router-link>
                            <a v-else href="javascript:void(0)" class="text-white text-decoration-none">
                                <div class="app-tile m-2 o-25">
                                    <font-awesome-icon icon="weight" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        田间农事自动化管控平台
                                        <!-- LandAutoDevice -->
                                        <!-- lad -->
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-4">
                            <router-link v-if="$store.state.user.userName=='admin'" to="/cm"
                                class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="video" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        农作物成品安控平台
                                        <!-- CropMonitoring -->
                                        <!-- cm -->
                                    </div>
                                </div>
                            </router-link>
                            <a v-else href="javascript:void(0)" class="text-white text-decoration-none">
                                <div class="app-tile m-2 o-25">
                                    <font-awesome-icon icon="video" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        农作物成品安控平台
                                        <!-- CropMonitoring -->
                                        <!-- cm -->
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-4">
                            <router-link to="/lm" class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="kaaba" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        土地管理平台
                                        <!-- LandManagement -->
                                        <!-- lm -->
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-4">
                            <router-link v-if="$store.state.user.userName=='admin'" to="/pp"
                                class="text-white text-decoration-none">
                                <div class="app-tile m-2">
                                    <font-awesome-icon icon="dice" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        土地种植方案测报平台
                                        <!-- PlantPredict -->
                                        <!-- pp -->
                                    </div>
                                </div>
                            </router-link>
                            <a v-else href="javascript:void(0)" class="text-white text-decoration-none">
                                <div class="app-tile m-2 o-25">
                                    <font-awesome-icon icon="dice" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        土地种植方案测报平台
                                        <!-- PlantPredict -->
                                        <!-- pp -->
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-4">
                            <a href="javascript:void(0)" class="text-white text-decoration-none">
                                <div class="app-tile m-2 o-25">
                                    <font-awesome-icon icon="suitcase" class="mt-4" style="font-size: 40px;" />
                                    <div class="mt-4">
                                        生产监管系统
                                        <!-- ProductionSupervision -->
                                        <!-- ps -->
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="text-center text-xl text-white text-shadow-black mt-4">
                        {{$store.state.applicationName}}
                    </div>
                </div>
            </div>

            <div class="position-fixed text-white py-3 px-4 text-shadow-black" style="top: 0; right: 0;">
                {{$store.state.user.name}}
                <a v-if="$store.state.user.userName=='admin'" href="/dp/views/index.html" target="_blank"
                    class="ml-4 text-decoration-none">大屏演示</a>
                <router-link to="/" target="_blank" class="ml-4 text-decoration-none">大屏</router-link>
                <a href="javascript:void(0)" class="ml-4 text-decoration-none" @click="logout">注销</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        methods: {
            logout() {
                if (!confirm('确定要注销吗？')) {
                    return;
                }

                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            if (this.$store.state.user.type != 1) {
                localStorage.removeItem('token');
                this.$store.dispatch('setUser', null);
                this.$router.push('/login');
            }
        },
    };
</script>

<style>
    .page-background {
        width: 100vw;
        height: 100vh;
        background-image: url('../../assets/background.jpg');
        background-position: center;
    }

    .app-tile {
        border-radius: 8px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
        padding: 24px 0;
        background-color: #ffffff1f;
    }

    .app-tile:hover {
        background-color: #ffffff0f;
    }

    .text-shadow-black {
        text-shadow: 1px 1px 1px black;
    }
</style>